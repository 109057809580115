export const SET_PATIENT = 'SET_PATIENT';
export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_ACCESSLIST = 'SET_ACCESSLIST';
export const SET_MESSAGE_TARGETS = 'SET_MESSAGE_TARGETS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_PRELOADS = 'SET_PRELOADS';
export const SET_CALENDAR = 'SET_CALENDAR';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_ROLES = 'SET_ROLES';
export const SET_SESSION = 'SET_SESSION';
export const SET_USER = 'SET_USER';
